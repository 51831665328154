var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import Component, { forceArray } from 'navigation/component/Component';
import { EASE_IN, EASE_OUT, frameToMs } from 'helpers/easings';
import DragController from 'components/DragController/DragController';
import mqStore from 'store/mqStore';
import { bindMethod } from 'helpers/bind';
import store from 'store';
import { durations } from './motion';
var MenuSecondPanelInner = /** @class */ (function (_super) {
    __extends(MenuSecondPanelInner, _super);
    function MenuSecondPanelInner() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "mobile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "visible", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onItemClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                store.drawer.set(null);
            }
        });
        Object.defineProperty(_this, "onSubCategoryUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (category) {
                var _a, _b, _c;
                _this.parent.category = category;
                var categoriesIncluded = ((_a = _this.refs.links) === null || _a === void 0 ? void 0 : _a.map(function (link) { return link.dataset.slug; })) || [];
                if (categoriesIncluded.includes(category)) {
                    (_b = _this.refs.links) === null || _b === void 0 ? void 0 : _b.forEach(function (link) {
                        link.classList.remove('active');
                        if (link.dataset.slug === category)
                            link.classList.add('active');
                        else
                            link.classList.add('inactive');
                    });
                }
                else {
                    (_c = _this.refs.links) === null || _c === void 0 ? void 0 : _c.forEach(function (link) {
                        link.classList.remove('active');
                        link.classList.remove('inactive');
                    });
                }
            }
        });
        Object.defineProperty(_this, "onBack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.emit('category:back');
            }
        });
        Object.defineProperty(_this, "onMqChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (mobile) {
                _this.mobile = mobile;
            }
        });
        return _this;
    }
    Object.defineProperty(MenuSecondPanelInner.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.name = this.el.dataset.category || '';
        }
    });
    Object.defineProperty(MenuSecondPanelInner.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                pushes: ['.menu-pushes__horizontal', DragController]
            };
        }
    });
    Object.defineProperty(MenuSecondPanelInner.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a;
            var method = bindMethod(add);
            mqStore.tabletPortrait.listenAndStart(this.onMqChange);
            this.refs.back[method]('click', function () { return _this.onBack(); });
            store.subcategory.toggleListener(add, this.onSubCategoryUpdate);
            (_a = this.refs.links) === null || _a === void 0 ? void 0 : _a.forEach(function (link) {
                link[method]('click', _this.onItemClick);
            });
        }
    });
    Object.defineProperty(MenuSecondPanelInner.prototype, "enter", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (reverse) {
            var _this = this;
            if (reverse === void 0) { reverse = false; }
            var m, m1;
            if (!this.mobile) {
                m = anime({
                    targets: [this.refs.links],
                    begin: function () {
                        _this.el.classList.add('visible');
                        _this.visible = true;
                    },
                    translateX: {
                        value: [-50, 0],
                        delay: anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { start: durations.FIRST_PANEL_TRANSLATE_OUT / 2 }),
                        duration: durations.SECOND_PANEL_ITEMS_TRANSLATE,
                        easing: EASE_OUT
                    },
                    opacity: {
                        value: [0, 1],
                        delay: anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { start: durations.SECOND_PANEL_ITEMS_OPACITY_IN_DELAY }),
                        duration: durations.SECOND_PANEL_ITEMS_OPACITY,
                        easing: 'linear'
                    }
                });
                if (this.modules.pushes) {
                    m1 = anime({
                        targets: [this.modules.pushes.refs.draggableItems],
                        opacity: {
                            value: [0, 1],
                            delay: anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { start: durations.SECOND_PANEL_ITEMS_OPACITY_IN_DELAY + this.refs.links.length * durations.SECOND_PANEL_ITEMS_STAGGER }),
                            duration: durations.SECOND_PANEL_ITEMS_OPACITY,
                            easing: 'linear'
                        }
                    });
                }
            }
            else {
                m = anime({
                    targets: [this.refs.back, this.refs.links],
                    begin: function () {
                        _this.el.classList.add('visible');
                        _this.visible = true;
                    },
                    translateX: {
                        value: reverse ? [-50, 0] : [50, 0],
                        delay: anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { start: (frameToMs(12)) }),
                        duration: durations.SECOND_PANEL_ITEMS_TRANSLATE,
                        easing: EASE_OUT
                    },
                    opacity: {
                        value: [0, 1],
                        delay: anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { start: (frameToMs(12)) }),
                        duration: durations.SECOND_PANEL_ITEMS_OPACITY,
                        easing: 'linear'
                    }
                });
                if (this.modules.pushes) {
                    m1 = anime({
                        targets: [this.modules.pushes.refs.draggableItems],
                        opacity: {
                            value: [0, 1],
                            delay: anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { start: (frameToMs(12)) + durations.SECOND_PANEL_ITEMS_TRANSLATE_IN_DELAY, from: this.refs.links.length + 1 }),
                            duration: durations.SECOND_PANEL_ITEMS_OPACITY,
                            easing: 'linear'
                        }
                    });
                }
            }
            this.motions.push(m, m1);
        }
    });
    Object.defineProperty(MenuSecondPanelInner.prototype, "leave", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (force) {
            var _this = this;
            if (force === void 0) { force = false; }
            var m, m1;
            if (!this.mobile) {
                m = anime({
                    targets: [this.refs.links],
                    translateX: {
                        value: [0, 50],
                        delay: force ? 0 : anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER),
                        duration: force ? 0 : durations.SECOND_PANEL_ITEMS_TRANSLATE,
                        easing: EASE_IN
                    },
                    opacity: {
                        value: [1, 0],
                        delay: force ? 0 : anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER),
                        duration: force ? 0 : durations.SECOND_PANEL_ITEMS_OPACITY,
                        easing: 'linear'
                    },
                    complete: function () {
                        _this.el.classList.remove('visible');
                        _this.visible = false;
                        _this.el.scrollTop = 0;
                    }
                });
                if (this.modules.pushes) {
                    m1 = anime({
                        targets: [this.modules.pushes.refs.draggableItems],
                        opacity: {
                            value: [1, 0],
                            delay: force ? 0 : anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { start: durations.SECOND_PANEL_ITEMS_OPACITY_IN_DELAY }),
                            duration: force ? 0 : durations.SECOND_PANEL_ITEMS_OPACITY,
                            easing: 'linear'
                        }
                    });
                }
            }
            else {
                m = anime({
                    targets: [this.refs.back, this.refs.links],
                    translateX: {
                        value: [0, 50],
                        delay: force ? 0 : anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { direction: 'reverse' }),
                        duration: force ? 0 : durations.SECOND_PANEL_ITEMS_TRANSLATE,
                        easing: EASE_IN
                    },
                    opacity: {
                        value: [1, 0],
                        delay: force ? 0 : anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { direction: 'reverse' }),
                        duration: force ? 0 : durations.SECOND_PANEL_ITEMS_OPACITY,
                        easing: 'linear'
                    },
                    complete: function () {
                        _this.el.classList.remove('visible');
                        _this.visible = false;
                        _this.el.scrollTop = 0;
                    }
                });
                if (this.modules.pushes) {
                    m1 = anime({
                        targets: [this.modules.pushes.refs.draggableItems],
                        opacity: {
                            value: [1, 0],
                            delay: anime.stagger(durations.SECOND_PANEL_ITEMS_STAGGER, { start: durations.SECOND_PANEL_ITEMS_OPACITY_IN_DELAY }),
                            duration: durations.SECOND_PANEL_ITEMS_OPACITY,
                            easing: 'linear'
                        }
                    });
                }
            }
            this.motions.push(m, m1);
        }
    });
    Object.defineProperty(MenuSecondPanelInner.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.cancelMotions();
            this.el.classList.remove('visible');
            this.el.removeAttribute('style');
            forceArray(this.refs.links).forEach(function (link) {
                link.removeAttribute('style');
            });
            if (this.modules.pushes) {
                forceArray(this.modules.pushes.refs.draggableItems).forEach(function (push) {
                    if (push)
                        push.style.opacity = '';
                });
            }
        }
    });
    Object.defineProperty(MenuSecondPanelInner.prototype, "cancelMotions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.motions.forEach(function (motion) {
                if (motion)
                    motion.pause();
            });
            this.motions = [];
        }
    });
    return MenuSecondPanelInner;
}(Component));
export default MenuSecondPanelInner;
