var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import anime from 'animejs';
import { durations } from 'components/menu/motion';
import scroll from 'core/scroll';
import { EASE_IN, EASE_OUT, frameToMs } from 'helpers/easings';
import Component, { forceArray } from 'navigation/component/Component';
import store from 'store/store';
var Search = /** @class */ (function (_super) {
    __extends(Search, _super);
    function Search() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'search'
        });
        Object.defineProperty(_this, "_opened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "openStart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "opening", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onItemOver", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e, type) {
                var e_1, _a;
                var target = e.currentTarget;
                try {
                    for (var _b = __values(_this.refs[type + 'Links']), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var link = _c.value;
                        link.classList.remove('active');
                        link.classList.add('inactive');
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                target.classList.remove('inactive');
                target.classList.add('active');
            }
        });
        Object.defineProperty(_this, "onItemOut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e, type) {
                var target = e.currentTarget;
                target.classList.remove('active');
                target.classList.add('inactive');
            }
        });
        Object.defineProperty(_this, "onListOut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e, type) {
                var e_2, _a;
                try {
                    for (var _b = __values(_this.refs[type + 'Links']), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var link = _c.value;
                        link.classList.remove('active');
                        link.classList.remove('inactive');
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        });
        Object.defineProperty(_this, "onDrawerChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (drawer) {
                _this.cancelMotions();
                if (drawer === _this.name) {
                    _this.open();
                }
                else {
                    if (_this.openStart || _this.opened || _this.opening)
                        _this.close();
                }
            }
        });
        Object.defineProperty(_this, "cancelMotions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var _a;
                (_a = _this.el.querySelector('input')) === null || _a === void 0 ? void 0 : _a.blur();
                _this.motions.forEach(function (motion) {
                    if (motion)
                        motion.pause();
                });
                _this.motions = [];
            }
        });
        Object.defineProperty(_this, "open", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                document.body.classList.add('header__search');
                scroll.lock();
                _this.opening = true;
                _this.openStart = true;
                var m = anime({
                    targets: _this.el,
                    clipPath: {
                        value: 'polygon(0 0, 100% 0%, 100% 100%, 0 100%)',
                        duration: durations.FIRST_PANEL_TRANSLATE_IN,
                        easing: EASE_OUT
                    },
                    opacity: {
                        value: 1,
                        duration: durations.FIRST_PANEL_OPACITY_IN / 2,
                        easing: 'linear'
                    },
                    begin: function () {
                        var _a;
                        _this.refs.inner.style.opacity = '1';
                        (_a = _this.el.querySelector('input')) === null || _a === void 0 ? void 0 : _a.focus();
                    },
                    complete: function () {
                        _this.opened = true;
                        _this.opening = false;
                        _this.openStart = false;
                    }
                });
                var m1 = anime({
                    targets: _this.refs.inner,
                    translateY: {
                        value: 0,
                        duration: durations.FIRST_PANEL_TRANSLATE_IN,
                        easing: EASE_OUT
                    }
                });
                var m2 = anime({
                    targets: [forceArray(_this.refs.popularLinks), forceArray(_this.refs.highlightLinks), forceArray(_this.refs.productLinks)].flat(),
                    translateY: {
                        value: [-5, 0],
                        duration: durations.FIRST_PANEL_TRANSLATE_IN,
                        easing: EASE_OUT,
                        delay: anime.stagger(frameToMs(1), { start: frameToMs(5) })
                    },
                    opacity: {
                        value: [0, 1],
                        duration: durations.FIRST_PANEL_OPACITY_IN,
                        easing: 'linear',
                        delay: anime.stagger(frameToMs(2), { start: frameToMs(5) })
                    }
                });
                _this.motions.push(m, m1, m2);
            }
        });
        Object.defineProperty(_this, "close", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                // this.el.querySelector('input')?.blur()
                var m = anime({
                    targets: _this.el,
                    clipPath: {
                        value: 'polygon(0 0, 100% 0%, 100% 80%, 0 80%)',
                        duration: durations.FIRST_PANEL_TRANSLATE_OUT,
                        easing: 'easeInSine'
                    },
                    opacity: {
                        value: 0,
                        duration: durations.FIRST_PANEL_OPACITY_OUT,
                        delay: (durations.FIRST_PANEL_OPACITY_OUT) / 2,
                        easing: 'linear'
                    },
                    complete: function () {
                        var _a;
                        document.body.classList.remove('header__search');
                        _this.opened = false;
                        _this.refs.inner.style.opacity = '';
                        scroll.unlock();
                        (_a = _this.el.querySelector('input')) === null || _a === void 0 ? void 0 : _a.blur();
                    }
                });
                var m1 = anime({
                    targets: _this.refs.inner,
                    translateY: {
                        value: -30,
                        duration: frameToMs(10),
                        easing: EASE_IN
                    },
                    opacity: {
                        value: 0,
                        duration: frameToMs(10),
                        easing: 'linear'
                    }
                });
                _this.motions.push(m, m1);
            }
        });
        return _this;
    }
    Object.defineProperty(Search.prototype, "opened", {
        get: function () {
            return this._opened;
        },
        set: function (value) {
            this._opened = value;
            this.el.classList.toggle('opened', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Search.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Search.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a, _b, _c, _d, _e, _f;
            store.drawer.toggleListener(add, this.onDrawerChange);
            (_a = this.refs.productLinks) === null || _a === void 0 ? void 0 : _a.forEach(function (link) {
                link.addEventListener('mouseover', function (e) { return _this.onItemOver(e, 'product'); });
                link.addEventListener('mouseout', function (e) { return _this.onItemOut(e, 'product'); });
            });
            (_b = this.refs.highlightLinks) === null || _b === void 0 ? void 0 : _b.forEach(function (link) {
                link.addEventListener('mouseover', function (e) { return _this.onItemOver(e, 'highlight'); });
                link.addEventListener('mouseout', function (e) { return _this.onItemOut(e, 'highlight'); });
            });
            (_c = this.refs.popularLinks) === null || _c === void 0 ? void 0 : _c.forEach(function (link) {
                link.addEventListener('mouseover', function (e) { return _this.onItemOver(e, 'popular'); });
                link.addEventListener('mouseout', function (e) { return _this.onItemOut(e, 'popular'); });
            });
            (_d = this.refs.productList) === null || _d === void 0 ? void 0 : _d.addEventListener('mouseout', function (e) { return _this.onListOut(e, 'product'); });
            (_e = this.refs.highlightList) === null || _e === void 0 ? void 0 : _e.addEventListener('mouseout', function (e) { return _this.onListOut(e, 'highlight'); });
            (_f = this.refs.popularList) === null || _f === void 0 ? void 0 : _f.addEventListener('mouseout', function (e) { return _this.onListOut(e, 'popular'); });
        }
    });
    return Search;
}(Component));
export default Search;
