var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defer, isArray } from 'lodash-es';
import anime from 'animejs';
import scroll from 'core/scroll';
import { bindMethod } from 'helpers/bind';
import store from 'store';
import modulesMap from 'core/modulesMap';
import { EASE_OUT, frameToMs } from 'helpers/easings';
import { durations } from 'components/menu/motion';
import Page from './Page';
var PanelPage = /** @class */ (function (_super) {
    __extends(PanelPage, _super);
    function PanelPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PanelPage.prototype, "askPreload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.el.classList.add('appearing');
                            return [4 /*yield*/, _super.prototype.askPreload.call(this, previousPage)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.resetScroll()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PanelPage.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.resize();
        }
    });
    Object.defineProperty(PanelPage.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign({}, modulesMap);
        }
    });
    Object.defineProperty(PanelPage.prototype, "resetScroll", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!((_a = this.pageManager) === null || _a === void 0 ? void 0 : _a.container.scrollTop)) return [3 /*break*/, 2];
                            return [4 /*yield*/, scroll.scrollTo(0, { target: this.pageManager.container, duration: 200 })];
                        case 1:
                            _b.sent();
                            _b.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
    });
    /* SHOW */
    Object.defineProperty(PanelPage.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage, previousEl) {
            return __awaiter(this, void 0, void 0, function () {
                var isIdentical, isSamePage;
                var _this = this;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.resize();
                            this.invoke('show');
                            isIdentical = (previousEl === null || previousEl === void 0 ? void 0 : previousEl.innerHTML) === ((_a = this.el) === null || _a === void 0 ? void 0 : _a.innerHTML);
                            if (isIdentical)
                                return [2 /*return*/, _super.prototype.show.call(this, previousPage, previousEl)];
                            isSamePage = previousPage === this.pageName() && previousPage !== PanelPage.pageName;
                            return [4 /*yield*/, anime({
                                    opacity: {
                                        value: [0, 1],
                                        duration: frameToMs(15),
                                        easing: 'linear',
                                        delay: frameToMs(15)
                                    },
                                    targets: this.el,
                                    translateX: {
                                        value: ['50%', '0%'],
                                        duration: isSamePage ? 0 : frameToMs(25),
                                        easing: EASE_OUT
                                    },
                                    changeBegin: function () {
                                    },
                                    complete: function () {
                                        defer(function () { return _this.resize(); });
                                    }
                                }).finished];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PanelPage.prototype, "transitionComplete", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.el.classList.remove('appearing');
            _super.prototype.transitionComplete.call(this);
        }
    });
    /* HIDE */
    Object.defineProperty(PanelPage.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (nextPage, previousEl) {
            return __awaiter(this, void 0, void 0, function () {
                var isIdentical, isSamePage;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            isIdentical = (previousEl === null || previousEl === void 0 ? void 0 : previousEl.innerHTML) === ((_a = this.el) === null || _a === void 0 ? void 0 : _a.innerHTML);
                            if (isIdentical)
                                return [2 /*return*/, _super.prototype.hide.call(this, nextPage, previousEl)];
                            isSamePage = nextPage === this.pageName() && nextPage !== PanelPage.pageName;
                            return [4 /*yield*/, anime(isSamePage ? {
                                    targets: this.el,
                                    opacity: {
                                        value: [1, 0],
                                        duration: frameToMs(10),
                                        easing: 'linear',
                                        delay: frameToMs(10)
                                    }
                                } : {
                                    targets: this.el,
                                    opacity: {
                                        value: [1, 0],
                                        duration: durations.FIRST_PANEL_OPACITY_OUT / 2,
                                        easing: 'linear'
                                    }
                                }).finished];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PanelPage.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a;
            _super.prototype.bindEvents.call(this, add);
            var method = bindMethod(add);
            if (isArray(this.refs.closePanel))
                this.refs.closePanel.forEach(function (el) { return el[method]('click', _this.closePanel); });
            else
                (_a = this.refs.closePanel) === null || _a === void 0 ? void 0 : _a[method]('click', this.closePanel);
        }
    });
    Object.defineProperty(PanelPage.prototype, "closePanel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            store.panel.set(null);
        }
    });
    Object.defineProperty(PanelPage.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
            this.resize();
        }
    });
    Object.defineProperty(PanelPage, "pageName", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 'PanelPage<Type'
    });
    return PanelPage;
}(Page));
export default PanelPage;
