var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { forceArray } from 'navigation/component/Component';
import anime from 'animejs';
import { EASE_OUT } from 'helpers/easings';
import { bindEmitterMethod } from 'helpers/bind';
import MenuPanel from './MenuPanel';
import { durations } from './motion';
import MenuSecondPanelInner from './MenuSecondPanelInner';
var MenuSecondPanel = /** @class */ (function (_super) {
    __extends(MenuSecondPanel, _super);
    function MenuSecondPanel() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "previousList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "_list", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "autoOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onCategoryBack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.close();
                _this.emit('category:change', null);
            }
        });
        // close () {
        //   let m
        //   if (this.mobile) {
        //     m = anime({
        //       targets: this.el,
        //       opacity: [1, 0],
        //       translateX: {
        //         value: [0, '100%'],
        //         duration: durations.SECOND_PANEL_TRANSLATE_OUT,
        //         easing: EASE_IN
        //       },
        //       duration: durations.SECOND_PANEL_OPACITY_IN,
        //       easing: 'linear'
        //     })
        //   }
        //   this.motions.push(m)
        // }
        Object.defineProperty(_this, "forceClose", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                anime.set(_this.el, {
                    translateX: '-100%',
                    opacity: 0
                });
                _this.cancelMotions();
            }
        });
        return _this;
    }
    Object.defineProperty(MenuSecondPanel.prototype, "list", {
        get: function () {
            return this._list;
        },
        set: function (value) {
            var _this = this;
            var _a, _b;
            this.previousList = this._list;
            var next = forceArray(this.modules.inners).find(function (inner) { return inner.name === value; });
            this._list = next ? value : null;
            if (this.previousList) {
                if (this.previousList !== value && value !== null)
                    (_a = forceArray(this.modules.inners).find(function (inner) { return inner.name === _this.previousList; })) === null || _a === void 0 ? void 0 : _a.leave();
                if (value === null)
                    (_b = forceArray(this.modules.inners).find(function (inner) { return inner.name === _this.previousList || inner.visible === true; })) === null || _b === void 0 ? void 0 : _b.leave(!this.mobile);
                next === null || next === void 0 ? void 0 : next.enter(true);
            }
            if (value && next && !this.previousList)
                this.open();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MenuSecondPanel.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                inners: ['.menu-level__second-inner', MenuSecondPanelInner]
            };
        }
    });
    Object.defineProperty(MenuSecondPanel.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            _super.prototype.bindEvents.call(this, add);
            var emitterMethod = bindEmitterMethod(add);
            forceArray(this.modules.inners).forEach(function (inner) {
                inner[emitterMethod]('category:back', _this.onCategoryBack);
            });
        }
    });
    Object.defineProperty(MenuSecondPanel.prototype, "open", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (switching) {
            var _this = this;
            var _a;
            if (switching === void 0) { switching = false; }
            (_a = forceArray(this.modules.inners).find(function (inner) { return inner.name === _this.list; })) === null || _a === void 0 ? void 0 : _a.enter(this.mobile && this.autoOpen);
            var m;
            if (!this.mobile) {
                m = anime({
                    targets: this.el,
                    translateX: {
                        value: ['-100%', 0],
                        duration: durations.SECOND_PANEL_TRANSLATE_IN,
                        easing: EASE_OUT
                    },
                    opacity: [1, 1]
                });
            }
            else {
                m = anime({
                    targets: this.el,
                    translateX: {
                        value: [0, 0]
                    },
                    opacity: {
                        value: [0, 1],
                        duration: durations.SECOND_PANEL_OPACITY_IN,
                        easing: 'linear'
                    },
                    begin: function () {
                        if (!_this.autoOpen)
                            _this.emit('open:begin');
                    },
                    complete: function () {
                        _this.emit('open:complete');
                        _this.autoOpen = false;
                    }
                });
            }
            this.motions.push(m);
        }
    });
    Object.defineProperty(MenuSecondPanel.prototype, "update", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
        }
    });
    Object.defineProperty(MenuSecondPanel.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.reset.call(this);
            this.el.style.transform = '';
            this.el.style.opacity = '';
            this.el.style.clipPath = '';
            this.list = null;
            this.autoOpen = false;
        }
    });
    Object.defineProperty(MenuSecondPanel.prototype, "cancelMotions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            forceArray(this.modules.inners).forEach(function (inner) { return inner.cancelMotions(); });
            _super.prototype.cancelMotions.call(this);
        }
    });
    return MenuSecondPanel;
}(MenuPanel));
export default MenuSecondPanel;
