var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Field from 'field/Field';
import Component from 'navigation/component/Component';
var PasswordField = /** @class */ (function (_super) {
    __extends(PasswordField, _super);
    function PasswordField(el, _a) {
        var parent = _a.parent;
        var _this = _super.call(this, el) || this;
        Object.defineProperty(_this, "button", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "placeholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                e.preventDefault();
                var show = _this.el.type === 'password';
                _this.el.type = show ? 'text' : 'password';
                _this.el.style.textTransform = show ? 'none' : 'uppercase';
                _this.el.placeholder = show ? '' : _this.placeholder;
                _this.button.innerText = _this.button.getAttribute('data-label').split('|')[show ? 1 : 0];
            }
        });
        _this.button = _this.el.parentNode.querySelector('.field__show');
        _this.placeholder = _this.el.placeholder;
        return _this;
    }
    Object.defineProperty(PasswordField.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                field: ['.field', Field]
            };
        }
    });
    Object.defineProperty(PasswordField.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            var method = add ? 'addEventListener' : 'removeEventListener';
            this.button[method]('click', this.onClick);
        }
    });
    return PasswordField;
}(Component));
export default PasswordField;
