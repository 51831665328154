import store from 'store/store'

const onStoreUpdate = (value, previous) => {
  const darkMode = store.darkPage.get() && !store.menu.get() && !store.search.get()
  store.darkMode.set(darkMode)
}

store.darkPage.listen(onStoreUpdate)
store.menu.listen(onStoreUpdate)
store.search.listen(onStoreUpdate)
