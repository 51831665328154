var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import anime from 'animejs';
import { last } from 'lodash';
import Component from 'navigation/component/Component';
import detect from 'helpers/detect';
import resize, { dimensions } from 'helpers/resize';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import Carousel from 'components/carousel/Carousel';
var ProductZoom = /** @class */ (function (_super) {
    __extends(ProductZoom, _super);
    function ProductZoom(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "mouseState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "savedOrder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "thumbFollowHeight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onClose", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.classList.remove('visible');
            }
        });
        Object.defineProperty(_this, "onThumbClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var mouseEvent = event; // Cast event to MouseEvent
                var thumbClickPosition = mouseEvent.clientY - (_this.refs.zoomThumbItems.getBoundingClientRect().top);
                var thumbClickRatio = thumbClickPosition / _this.refs.zoomThumbItems.scrollHeight;
                var scrollTarget = thumbClickRatio * _this.refs.listZoom.scrollHeight - dimensions().innerHeight / 2;
                _this.refs.listZoom.scrollTo({
                    top: scrollTarget,
                    behavior: 'smooth'
                });
            }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var scrollableHeight = _this.refs.listZoom.scrollHeight;
                var scrollY = _this.refs.listZoom.scrollTop;
                var thumbTop = (scrollY / scrollableHeight) * 100;
                var thumbFollowHeightPercentage = (_this.thumbFollowHeight / _this.refs.zoomThumbItems.scrollHeight) * 100;
                var adjustedThumbTop = Math.min(thumbTop, 100 - thumbFollowHeightPercentage);
                _this.refs.trackZoom.style.setProperty('--zoom-thumb-top', "".concat(adjustedThumbTop, "%"));
            }
        });
        Object.defineProperty(_this, "onRearrangeCarousel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (step) {
                var carousel = _this.modules.carousel;
                var lastIndex = carousel.items.length - 1;
                if (step <= 0) {
                    var firstItem = carousel.items[0];
                    var lastItem = last(carousel.items);
                    carousel.el.insertBefore(lastItem, firstItem);
                    carousel.items = Array.from(carousel.el.children);
                    carousel.resize();
                    _this.modules.carousel.goTo(1, true);
                }
                else if (step >= lastIndex) {
                    var firstItem = carousel.items[0];
                    carousel.el.appendChild(firstItem);
                    carousel.items = Array.from(carousel.el.children);
                    carousel.resize();
                    _this.modules.carousel.goTo(lastIndex - 1, true);
                }
            }
        });
        Object.defineProperty(_this, "onUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (step) {
                // this.onRearrangeCarousel(step)
            }
        });
        _this.mouseState = null;
        _this.thumbFollowHeight = 0;
        return _this;
    }
    Object.defineProperty(ProductZoom.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(ProductZoom.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                carousel: ['.product-zoom__inner', Carousel]
            };
        }
    });
    Object.defineProperty(ProductZoom.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
            this.savedOrder = this.modules.carousel.items.slice(0);
        }
    });
    Object.defineProperty(ProductZoom.prototype, "open", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            var _this = this;
            if (id === void 0) { id = 0; }
            this.resetState(true);
            this.savedOrder = this.modules.carousel.items.slice(0);
            this.savedOrder.forEach(function (item) {
                _this.modules.carousel.el.appendChild(item);
            });
            this.modules.carousel.items = Array.from(this.modules.carousel.el.children);
            this.refs.imageZoom.forEach(function (i) {
                i.src = i.getAttribute('data-src');
            });
            this.el.classList.add('visible');
            // scroll to the selected item
            var current = this.refs.itemsZoom[id];
            if (current)
                this.refs.listZoom.scrollTop = current.offsetTop;
            this.onScroll();
            setTimeout(function () {
                _this.resize();
            }, 100);
        }
    });
    Object.defineProperty(ProductZoom.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.call(this);
            var method = bindEmitterMethod(add);
            var methodNative = bindMethod(add);
            this.modules.carousel[method]('update', this.onUpdate);
            this.refs.listZoom[methodNative]('scroll', this.onScroll);
            this.refs.closeZoom[methodNative]('click', this.onClose);
            this.refs.zoomThumbItems[methodNative]('click', this.onThumbClick);
        }
    });
    Object.defineProperty(ProductZoom.prototype, "updateState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (state) {
            if (detect.touch || state === this.mouseState)
                return;
            this.el.classList.remove(this.mouseState);
            this.el.classList.add(state);
            this.mouseState = state;
            if (state !== 'close')
                this.bait(state);
            else
                this.animateOffset(0);
        }
    });
    Object.defineProperty(ProductZoom.prototype, "resetState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (direct) {
            if (direct === void 0) { direct = false; }
            if (direct)
                this.modules.carousel.shift = 0;
            else
                this.animateOffset(0);
            this.el.classList.remove(this.mouseState);
            this.mouseState = null;
        }
    });
    Object.defineProperty(ProductZoom.prototype, "bait", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (state) {
            var offset = (state === 'prev' ? 1 : -1) * resize.width() * .15;
            this.animateOffset(offset);
        }
    });
    Object.defineProperty(ProductZoom.prototype, "animateOffset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (shift) {
            var carousel = this.modules.carousel;
            if (carousel.shift === shift)
                return;
            anime.remove(carousel);
            return anime({
                targets: carousel,
                shift: shift,
                easing: 'easeOutCubic',
                duration: 400,
                complete: function () { return carousel.updatePosition(true); },
                change: function () { return carousel.updatePosition(true); }
            });
        }
    });
    Object.defineProperty(ProductZoom.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            var wrapperHeight = this.refs.listZoom.scrollHeight - 25;
            var vh = window.innerHeight;
            var wrapperThumbHeight = this.refs.zoomThumbItems.scrollHeight;
            this.thumbFollowHeight = (wrapperThumbHeight * vh) / wrapperHeight;
            this.refs.trackZoom.style.setProperty('--zoom-thumb-height', "".concat(this.thumbFollowHeight, "px"));
        }
    });
    return ProductZoom;
}(Component));
export default ProductZoom;
