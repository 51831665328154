import Form from 'components/form/Form';
import AddressForm from 'components/form/AddressForm';
import PasswordField from 'components/password-field/PasswordField';
import HomeProducts from 'sections/home/partials/HomeProducts';
import HomeDiptychProducts from 'sections/home/partials/HomeDiptychProducts';
import AdaptableWrapper from 'components/adaptable-wrapper/AdaptableWrapper';
import NativeSelect from 'components/native-select/NativeSelect';
import WebstoreSwitcher from 'components/webstore-switcher/WebstoreSwitcher';
import Player from 'components/player/Player';
import Footer from 'components/footer/Footer';
import Facets from 'components/facets/Facets';
import Button from 'components/button/Button';
import RevealList from 'components/reveal-list/RevealList';
import SocialLogin from 'components/social-login/SocialLogin';
import Field from 'field/Field';
import RadioGroup from 'components/radio-group/RadioGroup';
import DrawerPanel from 'components/drawer-panel/DrawerPanel';
import SwitchButton from 'components/switch-button/SwitchButton';
var modulesMap = {
    form: ['.form', Form],
    passwordField: ['.field input[type="password"]', PasswordField],
    field: ['.field', Field],
    switchButton: ['.switch-button', SwitchButton],
    radioGroup: ['.radio-group', RadioGroup],
    addressForm: ['.address-form', AddressForm],
    nativeSelect: ['.native-select', NativeSelect],
    homeProducts: ['.home-products', HomeProducts],
    homeDiptychProducts: ['.home-diptych-products', HomeDiptychProducts],
    player: ['.player', Player],
    drawer: ['.drawer-panel', DrawerPanel],
    revealList: ['.reveal-list', RevealList],
    adaptableWrapper: ['.adaptable-wrapper', AdaptableWrapper],
    webstoreSwitcher: ['.webstore-switcher', WebstoreSwitcher],
    facets: ['.facets', Facets],
    button: ['.button', Button],
    footer: ['.footer', Footer],
    socialLogin: ['.social-login', SocialLogin]
};
export default modulesMap;
