var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import PasswordField from 'components/password-field/PasswordField';
import SocialLogin from 'components/social-login/SocialLogin';
import PanelPage from 'navigation/pages/PanelPage';
var Auth = /** @class */ (function (_super) {
    __extends(Auth, _super);
    function Auth() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "__scrollable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        return _this;
    }
    Object.defineProperty(Auth.prototype, "scrollable", {
        get: function () {
            return this.__scrollable;
        },
        set: function (value) {
            this.__scrollable = value;
            this.el.classList.toggle('scrollable', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Auth.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { passwordField: ['.field input[type="password"]', PasswordField], socialLogin: ['.social-login', SocialLogin] });
        }
    });
    Object.defineProperty(Auth.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.detectScrollable();
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Auth.prototype, "detectScrollable", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.el.scrollHeight - 1 > this.el.clientHeight)
                this.scrollable = true;
            else
                this.scrollable = false;
        }
    });
    Object.defineProperty(Auth.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.detectScrollable();
        }
    });
    Object.defineProperty(Auth.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    Object.defineProperty(Auth, "pageName", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 'Auth'
    });
    return Auth;
}(PanelPage));
export default Auth;
