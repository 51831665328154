var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import Component from 'navigation/component/Component';
import scroll from 'core/scroll';
var BlueshiftPopin = /** @class */ (function (_super) {
    __extends(BlueshiftPopin, _super);
    function BlueshiftPopin(el, options) {
        var _this = this;
        var w = window;
        _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "open", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.classList.add('opened');
                scroll.lock(true);
            }
        });
        Object.defineProperty(_this, "close", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.classList.remove('opened');
                scroll.unlock(true);
                _this.observer.disconnect();
            }
        });
        Object.defineProperty(_this, "onPopinUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (mutation) {
                var notEmpty = mutation.addedNodes.length > 0;
                if (notEmpty)
                    _this.open();
                else
                    _this.close();
            }
        });
        if (w.blueshift)
            w.blueshift.live();
        _this.bindRefs();
        return _this;
    }
    Object.defineProperty(BlueshiftPopin.prototype, "bindRefs", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindRefs.apply(this, __spreadArray([], __read(arguments), false));
        }
    });
    Object.defineProperty(BlueshiftPopin.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a, _b;
            if (add === void 0) { add = true; }
            var method = add ? 'addEventListener' : 'removeEventListener';
            this.mutationObserver(add);
            this.refs.overlayPanel[method]('click', this.close);
            if ((_b = (_a = this.refs.popinInner) === null || _a === void 0 ? void 0 : _a.innerHTML) === null || _b === void 0 ? void 0 : _b.length)
                setTimeout(function () { return _this.open(); }, 400);
        }
    });
    Object.defineProperty(BlueshiftPopin.prototype, "mutationObserver", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var callback = function (mutationList) {
                var e_1, _a;
                try {
                    for (var mutationList_1 = __values(mutationList), mutationList_1_1 = mutationList_1.next(); !mutationList_1_1.done; mutationList_1_1 = mutationList_1.next()) {
                        var mutation = mutationList_1_1.value;
                        if (mutation.type === 'childList')
                            _this.onPopinUpdate(mutation);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (mutationList_1_1 && !mutationList_1_1.done && (_a = mutationList_1.return)) _a.call(mutationList_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            };
            this.observer = new MutationObserver(callback);
            this.observer.observe(this.el, {
                attributes: true,
                childList: true,
                subtree: true
            });
        }
    });
    return BlueshiftPopin;
}(Component));
export default BlueshiftPopin;
