import promise from 'helpers/promise'
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager'
import CheckoutPage from 'navigation/pages/CheckoutPage'
import InternalPage from 'navigation/pages/InternalPage'
import store from 'store'

class CheckoutShipping extends CheckoutPage { 
 static pageName = 'CheckoutShipping'; 
  bindEvents (add = true) {
    super.bindEvents(...arguments)
    store.checkoutPickup[add ? 'listen' : 'unlisten'](this.onPickupSelected)
  }

  bindModules () {
    super.bindModules(...arguments)
    this.modules.form.setSubmitCallback(this.validForm)
  }

  onPickupSelected = (value) => {
    if (!this.pickupAsync) return
    this.pickupAsync.data.set('pickupId', value)
    this.pickupAsync.resolve()
  }

  validForm = (formData) => {
    if (formData.get('carrier') === '6') {
      this.pickupAsync = promise.defer()
      this.pickupAsync.data = formData
      this.modules.form.setSubmitted(false)
      this.openPickupPopin(formData)

      return this.pickupAsync.promise
    }
  }

  openPickupPopin (formData) {
    const link = this.modules.form.el.getAttribute('data-pickup')
    if (!link || this.loading) return
    this.loading = true

    const xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    xhr.open('POST', link)
    xhr.responseType = 'document'
    xhr.onload = this.onXhrComplete
    xhr.send(formData)
  }

  onXhrComplete = (event) => {
    this.loading = false
    const xhr = event.currentTarget
    xhr.onload = null

    const data = extractInfoFromXhr(xhr)
    if (!data.error) {
      this.options.parent.modules.popin.pageManager.inject(xhr.responseURL, xhr)
    } else {
      const phone = this.modules.form.el.querySelector('[name="phone"]')
      if (phone) phone.classList.add('error')
    }
  }

  resize () {
    super.resize()
  }

  flush () {
    super.flush()
  }
}

export default CheckoutShipping
