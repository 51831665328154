var findLinks = function () { return [].slice.call(document.querySelectorAll('[data-event]')); };
var getEvent = function (el) { return el.getAttribute('data-event') ? JSON.parse(el.getAttribute('data-event')) : null; };
var triggerItem = function (item) {
    var gaEvent = getEvent(item);
    if (gaEvent && gaEvent.event === 'select_item') {
        localStorage.setItem('list_name', gaEvent.ecommerce.item_list_name);
        localStorage.setItem('list_index', gaEvent.ecommerce.items[0].index);
    }
    if (window.dataLayer && gaEvent)
        window.dataLayer.push(gaEvent);
};
var triggerListTag = function (lists) {
    Object.keys(lists).map(function (listName) {
        return {
            item_list_name: listName,
            items: lists[listName]
        };
    }).forEach(function (list) {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'view_item_list',
                ecommerce: list
            });
        }
    });
};
var triggerProductItem = function (item) {
    var gaEvent = getEvent(item);
    if (gaEvent)
        gaEvent.event = 'view_item_list';
    if (window.dataLayer && gaEvent)
        window.dataLayer.push(gaEvent);
};
var updatePageEvents = function () {
    if (typeof document === 'undefined')
        return;
    findLinks().forEach(function (item) {
        if (!item.hasTagAttached) {
            var eventName = item.tagName.toUpperCase() === 'FORM' ? 'submit' : 'click';
            item.addEventListener(eventName, function (e) {
                triggerItem(item);
            });
            item.hasTagAttached = true;
        }
    });
};
var lastTags = {};
var openPopin = function (name, store) {
    triggerPopin(name, true);
    lastTags[store] = name;
};
var closePopin = function (store) {
    if (lastTags[store]) {
        triggerPopin(lastTags[store], false);
        lastTags[store] = null;
    }
};
var triggerPopin = function (name, state) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'view_popin',
            popin_name: name,
            popin_state: state ? 'open' : 'close'
        });
    }
};
var tag = { updatePageEvents: updatePageEvents, triggerItem: triggerItem, openPopin: openPopin, closePopin: closePopin, triggerProductItem: triggerProductItem, getEvent: getEvent, triggerListTag: triggerListTag };
export default tag;
