var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { delay } from 'lodash';
import VirtualPageManager from 'navigation/page-manager/VirtualPageManager';
import { State } from 'helpers/state';
import InternalRouter from 'navigation/pages/InternalRouter';
import PanelPage from 'navigation/pages/PanelPage';
import store from 'store';
import EditorialRelatedContent from 'components/editorial-related-content/EditorialRelatedContent';
import Footer from 'components/footer/Footer';
import { bindMethod } from 'helpers/bind';
import CheckoutPage from 'navigation/pages/CheckoutPage';
import AdaptableWrapper from 'components/adaptable-wrapper/AdaptableWrapper';
import CheckoutBreadcrumb from './partials/CheckoutBreadcrumb';
import CheckoutPopin from './partials/CheckoutPopin';
import CheckoutSummary from './partials/CheckoutSummary';
var Checkout = /** @class */ (function (_super) {
    __extends(Checkout, _super);
    function Checkout() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "onSummaryButtonClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                store.checkoutSummaryOpened.set(!store.checkoutSummaryOpened.get());
            }
        });
        Object.defineProperty(_this, "onSummaryUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var _a = _this.modules, content = _a.content, summary = _a.summary;
                if (content.currentPage.pageName() === 'CheckoutPayment' && summary) {
                    summary.once('show', function () {
                        content.refresh();
                    });
                }
            }
        });
        return _this;
    }
    Object.defineProperty(Checkout.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.testStep(this.el);
        }
    });
    Object.defineProperty(Checkout.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { popin: ['.checkout-popin', CheckoutPopin], summaryItem: ['.checkout__summary:not([default-route]) > *', CheckoutSummary], breadcrumb: ['.checkout-breadcrumb', CheckoutBreadcrumb], editorialRelatedContent: ['.editorial-related-content', EditorialRelatedContent], adaptableWrapper: ['.adaptable-wrapper', AdaptableWrapper], footer: ['footer', Footer] });
        }
    });
    Object.defineProperty(Checkout.prototype, "preload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    if (!this.modules.summary)
                        return [2 /*return*/, _super.prototype.preload.call(this)];
                    return [2 /*return*/, Promise.all([
                            _super.prototype.preload.call(this),
                            new Promise(function (resolve) { return _this.modules.summary.once('loaded', resolve); })
                        ])];
                });
            });
        }
    });
    Object.defineProperty(Checkout.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
            if (this.refs.checkoutSummary.hasAttribute('default-route')) {
                if (!this.modules.summary)
                    this.modules.summary = this.initSummaryRouter();
            }
            else if (this.modules.summaryItem) {
                var summaryItem_1 = this.modules.summaryItem;
                summaryItem_1.askPreload().then(function () { return summaryItem_1.askShow(); });
            }
        }
    });
    Object.defineProperty(Checkout.prototype, "openPopin", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (url) {
            this.modules.popin.store.set(url);
        }
    });
    Object.defineProperty(Checkout.prototype, "initSummaryRouter", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return new VirtualPageManager(new State(null), this.refs.checkoutSummary, '.page', PanelPage);
        }
    });
    Object.defineProperty(Checkout.prototype, "getPageSelector", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return '.checkout__step';
        }
    });
    Object.defineProperty(Checkout.prototype, "getDefaultPageClass", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return CheckoutPage;
        }
    });
    Object.defineProperty(Checkout.prototype, "shouldRouteInternally", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            if (this.step >= 5)
                return false;
            var page = el.querySelector(this.getPageSelector());
            if (!page)
                return false;
            var step = page.getAttribute('data-step');
            if (step)
                return (+step) < 5;
        }
    });
    Object.defineProperty(Checkout.prototype, "testStep", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            var page = el.querySelector(this.getPageSelector());
            if (!page)
                return;
            var step = page.getAttribute('data-step');
            if (step) {
                this.step = +step;
                store.checkoutStep.set(+step);
            }
        }
    });
    Object.defineProperty(Checkout.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.apply(this, __spreadArray([], __read(arguments), false));
            var summary = this.modules.summary;
            var method = bindMethod(add);
            summary && summary[add ? 'on' : 'off']('virtual', this.onSummaryUpdate);
            this.refs.summaryButton[method]('click', this.onSummaryButtonClick);
        }
    });
    Object.defineProperty(Checkout.prototype, "internalRouting", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName_1, xhr_1) {
            return __awaiter(this, arguments, void 0, function (pathName, xhr, requestOptions) {
                var summary, refresh, container;
                if (requestOptions === void 0) { requestOptions = {}; }
                return __generator(this, function (_a) {
                    this.testStep(xhr.response);
                    this.modules.content.forceRouteUpdate(pathName, xhr, requestOptions);
                    summary = this.modules.summary;
                    refresh = function () { return summary && summary.refresh(); };
                    container = this.refs.checkoutSummary;
                    if (container.classList.contains('opened')) {
                        this.refs.checkoutSummary.classList.remove('opened');
                        delay(refresh, 400);
                    }
                    else {
                        refresh();
                    }
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(Checkout.prototype, "refreshPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a, _b;
            (_b = (_a = this.modules) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.refresh();
        }
    });
    return Checkout;
}(InternalRouter));
export default Checkout;
