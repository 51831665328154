var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
var NativeSelect = /** @class */ (function (_super) {
    __extends(NativeSelect, _super);
    function NativeSelect() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "onChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var select = e.currentTarget;
                _this.emit('change', select.options[select.selectedIndex]);
                _this.updateLabel();
                _this.el.classList.add('filled');
            }
        });
        return _this;
    }
    Object.defineProperty(NativeSelect.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.updateLabel();
        }
    });
    Object.defineProperty(NativeSelect.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _a;
            var method = bindMethod(add);
            if (this.refs.select)
                this.refs.select[method]('change', this.onChange);
            else
                (_a = this.el.querySelector('select')) === null || _a === void 0 ? void 0 : _a[method]('change', this.onChange);
        }
    });
    Object.defineProperty(NativeSelect.prototype, "updateLabel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.refs.select && this.refs.select.value && this.refs.nativeSelectLabel)
                this.refs.nativeSelectLabel.textContent = this.refs.select.value;
        }
    });
    return NativeSelect;
}(Component));
export default NativeSelect;
