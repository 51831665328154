var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Footer from 'components/footer/Footer';
import Form from 'components/form/Form';
import modulesMap from 'core/modulesMap';
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager';
import MainPage from 'navigation/pages/MainPage';
import NativeSelect from '../../components/native-select/NativeSelect';
var Contact = /** @class */ (function (_super) {
    __extends(Contact, _super);
    function Contact() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onFormSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (formData, urlParameters) {
                var form = _this.modules.form;
                var xhr = new XMLHttpRequest();
                _this.loading = true;
                xhr.withCredentials = true;
                xhr.open(form.method, form.action, true);
                xhr.responseType = 'document';
                xhr.onload = _this.onXhrComplete;
                xhr.send(formData);
                return false;
            }
        });
        Object.defineProperty(_this, "onXhrComplete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var form = _this.modules.form;
                form.submitted = false;
                _this.loading = false;
                var xhr = event.currentTarget;
                extractInfoFromXhr(xhr);
                xhr.onload = null;
                _this.modules.form.clear();
            }
        });
        return _this;
    }
    Object.defineProperty(Contact.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                adaptableWrapper: modulesMap.adaptableWrapper,
                footer: ['footer', Footer],
                form: ['.contact__form', Form],
                select: ['.native-select', NativeSelect]
            };
        }
    });
    Object.defineProperty(Contact.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.modules.form.submitCallback = this.onFormSubmit;
        }
    });
    Object.defineProperty(Contact.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            this.el.style.setProperty('--submit-wrapper-height', "".concat(this.refs.submitWrapper.clientHeight, "px"));
            this.el.style.setProperty('--contact-header-height', "".concat((_a = this.refs.header) === null || _a === void 0 ? void 0 : _a.clientHeight, "px"));
        }
    });
    return Contact;
}(MainPage));
export default Contact;
