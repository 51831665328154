var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defer, pickBy, omit } from 'lodash';
import store from 'store';
import CheckoutPage from 'navigation/pages/CheckoutPage';
var CheckoutAddress = /** @class */ (function (_super) {
    __extends(CheckoutAddress, _super);
    function CheckoutAddress() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "onPanelChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (panelValue) {
                var _a;
                if (panelValue === null)
                    (_a = _this.parent) === null || _a === void 0 ? void 0 : _a.refreshPage();
            }
        });
        return _this;
    }
    Object.defineProperty(CheckoutAddress.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.apply(this, __spreadArray([], __read(arguments), false));
            var method = add ? 'on' : 'off';
            this.modules.radioGroup.forEach(function (r) { return r[method]('change', _this.onMethodChange, _this); });
            store.panel.toggleListener(add, this.onPanelChange, this);
            if (add)
                this.onMethodChange();
        }
    });
    Object.defineProperty(CheckoutAddress.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
            var form = this.modules.form;
            var validations = Object.assign({}, form.validations);
            var billingValidation = pickBy(validations, function (v, k) { return !!~k.indexOf('billing___'); });
            var deliveryValidation = pickBy(validations, function (v, k) { return !!~k.indexOf('delivery___'); });
            var baseValidation = omit(validations, __spreadArray(__spreadArray([], __read(Object.keys(billingValidation)), false), __read(Object.keys(deliveryValidation)), false));
            this.validations = {
                default: validations,
                billing: billingValidation,
                delivery: deliveryValidation,
                base: baseValidation
            };
        }
    });
    Object.defineProperty(CheckoutAddress.prototype, "onMethodChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            defer(function () {
                var data = _this.modules.radioGroup.reduce(function (memo, radio) {
                    memo[radio.name] = radio.value;
                    return memo;
                }, {});
                var schema = __assign({}, _this.validations.base);
                var form = _this.modules.form;
                if (data.address_choice === 'new')
                    Object.assign(schema, _this.validations.delivery);
                if (data.same_address === '0')
                    Object.assign(schema, _this.validations.billing);
                form.updateSchema(schema);
                setTimeout(function () { return form.quickValid(); }, 10);
            });
        }
    });
    Object.defineProperty(CheckoutAddress.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(CheckoutAddress.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    Object.defineProperty(CheckoutAddress, "pageName", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 'CheckoutAddress'
    });
    return CheckoutAddress;
}(CheckoutPage));
export default CheckoutAddress;
